.admin-card .card-body {
  padding-top: 0;
}

iframe {
  pointer-events: none;
}

.admin-card .card-body .pull-right {
  float: left;
}

.admin-card .card-body .btn-popup {
  margin-bottom: 0;
}

.admin-input {
  padding: 0;
}

.admin-card .data-table-extensions {
  padding-top: 10px;
}

.admin-card .data-table-extensions input {
  float: right;
  padding: 10px;
}

.admin-card .data-table-extensions input.filter-text,
.admin-card .data-table-extensions input.filter-text:focus,
.admin-card .data-table-extensions input.filter-text:focus-visible {
  border: 1px solid #ddd !important;
  outline: 1px;
  border-radius: 0.25rem;
}

.admin-type .admin-intype {
  padding: 0;
}

/* .admin-type input.form-control:hover {
    border: 1px solid #000;
} */
.admin-card .pull-right {
  float: left;
}

/*****************8 In put ****************/

.file-upload {
  display: block;
  text-align: center;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  border: 1px solid #ced4da;
  color: #fff;
  cursor: pointer;
  min-height: 80px;
  max-height: 80px;
  padding: 5px 10px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.file-select img {
  max-height: 80px;
  padding: 7px;
  width: auto !important;
}

.desc textarea {
  min-height: 80px;
}

.file-upload .file-select .file-select-button {
  background: #083248;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: 0.25rem;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  color: #000;
}

/* .file-upload .file-select:hover {
    border-color: #34495e;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
} */
.file-upload .file-select:hover .file-select-button {
  /* background: #34495e; */
  background-color: #ff4d53;
  color: #fff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
  /* border-color: #3fa46a; */
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  /* background: #3fa46a; */
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 1px solid #ced4da;
  color: #fff;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.rdt_TableHeadRow {
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
  position: relative;
}

.rdt_TableCell {
  text-align: center;
  padding: 7px 5px;
  background-color: #f8f8f9 !important;
  margin: 8px 5px;
  display: flex;
  justify-content: center;
  color: rgb(0, 0, 0) !important;
  line-height: normal;
  position: relative;
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  font-size: 14px;
  text-transform: capitalize;
}

.rdt_TableCol {
  font-size: 15px !important;
  text-align: center;
  padding: 18px;
  background-color: #f8f8f9 !important;
  margin: 5px 5px;
  display: flex;
  justify-content: center;
  color: rgb(0, 0, 0) !important;
  line-height: normal;
  position: relative;
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.rdt_TableCol div {
  font-weight: 600 !important;
  font-size: 13px !important;
  white-space: nowrap;
}

/********** Start  Style for add product ************/
.product-adding-page .dropzone {
  border: 2px dashed #908f8f;
}

/* .file-upload .file-select .file-select-button {
    background: #083248  !important;
} */

.fa {
  font-family: "FontAwesome" !important;
}

/********** Datae:14/03/22 Start style for upload product img preview ************/
.product-adding-page .upload-img-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.product-adding-page .upload-img-preview .img-preview {
  width: 20%;
  padding: 8px;
  margin: 0 5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%);
  border: 1px solid #acacac57;
  position: relative;
}

.product-adding-page .upload-img-preview .img-preview i.fa.fa-times {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}

.product-adding-page .upload-img-preview .img-preview img {
  max-width: 140px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/********** Datae:14/03/22 End Style for upload product img preview************/

/********** Datae:15/03/22 Start Style for product ************/
.product-list-page .product-img {
  width: 350px;
  max-width: 100%;
  /* height: 450px;
    object-fit: cover; */
  height: 250px;
  object-fit: cover;
  object-position: top;
}

.product-list-page .products-admin .product-box .lable-block .lable3 {
  border-radius: 5px;
  background-color: #977DFE;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding: 3px 6px;
  text-transform: uppercase;
  color: #ffffff;
  top: 20px;
  left: unset;
  right: 20px;
  z-index: 1;
}

/********** Datae:15/03/22 End Style for product ************/
.btn-secondary {
  background-color: #083248 !important;
  border-color: #083248 !important;
}

/* .btn-secondary:hover, .btn-secondary.active {
    background-color: #ff4d53 !important;
    border-color: #ff4d53 !important;
} */
.product-list-page .product-image img {
  height: 237px;
  object-fit: contain;
}

/******* loder css ******/
.admin-card {
  position: relative;
}

.action-btn {
  display: flex;
  align-items: center;
}

.action-btn i {
  cursor: pointer;
}

.delete-modal-dialog .modal-content .cancel-button {
  background-color: #919191 !important;
  border-color: #919191 !important;
  border-radius: 30px;
}

.profile-image img {
  /* height: 50px !important;
    width: 50px !important; */
  height: 120px !important;
  width: 120px !important;
  border: 1px solid gainsboro;
  border-radius: 4px !important;
  object-fit: contain !important;
}

/* Work On 16-03-2022 */
/* Add Box Page */
.addbox-form.card {
  padding: 20px;
}

.addbox-input,
.addbox-select {
  padding: 10px 6px;
  position: relative;
}

.err-message {
  position: absolute;
}

.search-box-section {
  padding: 10px 20px;
  box-shadow: 0px 0px 10px 0px #cecece;
  border-radius: 0.25em;
}

input.search-box {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem 0.375rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline-color: #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-icon {
  position: absolute;
  top: 50px;
  right: 15px;
}

.dollar-icon {
  position: absolute;
  top: 50px;
  right: 15px;
}

input.search-box input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.search-section {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  color: #083248;
}

.category-search {
  display: block;
  width: fit-content;
  /* height: 40px; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline-color: #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 10px;
}

.search-category {
  display: flex;
  justify-content: flex-end;
}

.search_boxes_section .search_category {
  margin-bottom: 10px;
}

.searched-content-section {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
  margin-top: 10px;
  border: 1px solid #ededed;
}

.searched-content-section::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.searched-content-section::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: rgba(2, 79, 123, 0.5);
}

.search-list {
  margin: 15px 0;
  position: relative;
  cursor: pointer;
}

.search-list:before {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
}

.search-list:hover:before {
  content: "";
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.middle-btn-selected {
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 24%;
}

.search-list:hover .middle-btn-selected {
  opacity: 1;
  z-index: 9;
}

.middle-btn-selected span {
  color: #fff;
  padding: 10px 15px;
  border-radius: 0.25rem;
  border: 2px solid #fff;
}

.search-list img {
  width: 100%;
  border-radius: 0.25rem;
  color: #024f7b;
  height: 150px;
  object-fit: cover;
  object-position: top;
}

.search-price {
  position: absolute;
  top: 6px;
  right: 5px;
  font-size: 14px;
  color: #ffffff;
  background: #083248;
  line-height: 12px;
  padding: 5px 8px;
  border-radius: 0.25em;
}

.search-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  margin-bottom: 0;
  padding: 5px;
  /* background: #191a2a; */
  color: #fff;
}

.small-slick div .item {
  border: 1px solid #fff;
}

.choose-odds-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.choose-odds {
  /* border: 1px solid #cecece; */
  box-shadow: 0 0 10px 0 #cecece;
  border-radius: 10px;
}

.choose-odds-content {
  /* display: grid;
        grid-template-columns: 10% 30% 20% 20% 20%; */
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.choose-odds-content div {
  border-right: 1px solid #cecece;
  padding: 10px;
  display: inline-flex;
  align-content: center;
  align-items: center;
  min-height: 90px;
}

.choose-odds.boxthead div {
  min-height: unset;
}

.boxthead {
  background-color: #2A2D35 !important;
  color: #fff !important;
}

.boxtbody h5 {
  color: white !important;
}

.choose-editable {
  padding: 5px 6px;
}

.odds-icon {
  font-size: 20px;
  text-align: center;
  justify-content: center;
}

.choose-odds-content div:last-child {
  border-right: none;
  display: flex;
  justify-content: center;
}

.choose-odds-content div h5 {
  margin-bottom: 0;
  color: #083248;
}

.choose-odds-content div input {
  border-radius: 0.25rem;
  padding: 0.48rem 0.75rem;
  font-size: 14px;
  width: 70%;
  height: 40px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.choose-odds-content div input:focus {
  border: 1px solid #ced4da !important;
  outline: #ced4da;
}

.del-icon {
  margin-left: 10px;
  color: #fff;
  font-size: 28px;
  background-color: #ff8084 !important;
  border-color: #ff8084 !important;
  padding: 5px;
}

@media (max-width: 780px) {
  .choose-odds-content {
    display: flex;
    text-align: center;
  }

  .choose-odds-content div {
    /* border: 1px solid #cecece; */
    padding: 5px;
    margin: 3px;
    display: inline-flex;
    min-width: 30%;
    min-height: 75px;
  }
}

/* Pagination In Add Box Page */
.search-pagination li {
  padding: 5px 8px;
  margin: 0;
}

/* .search-pagination li.selected {
        text-decoration: underline;
    } */
.search-pagination li.selected a {
  font-weight: bold;
  color: #fff !important;
  background: #083248 !important;
}

.search-pagination li a {
  color: #083248 !important;
  border: 2px solid #083248;
  width: 25px;
  height: 25px;
  line-height: 16px;
  font-size: 16px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.search-pagination li.previous,
.search-pagination li.nex {
  padding: 5px 0;
}

.search-pagination li.previous a,
.search-pagination li.next a {
  font-family: "FontAwesome" !important;
  background: #ffffff;
  border: none;
  color: #7c7c7c !important;
  /* padding: 2px 5px; */
  line-height: 18px;
  font-size: 18px;
  border-radius: 50%;
  display: inline-flex;

  align-items: center;
  justify-content: center;
}

.search-pagination li.previous a:after {
  content: "\f053";
}

.search-pagination li.next a:after {
  content: "\f054";
}

.file-upload img.preview-imagebox {
  height: 200px;
  width: 100%;
  object-fit: contain;
  background: #f7f7f9;
  margin: 10px 0px;
  border-radius: 5px;
}

/******** Date:21-03-22 Theme color changes and design *******/
.logo-wrapper.main-logo img {
  width: 220px;
  height: auto;
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left .logo-wrapper {
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-page .card-bg {
  background-color: #083248 !important;
  color: #ffffff;
  padding: 30px;
}

.static-top-widget .icons-widgets .text-center {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.static-top-widget .media-body h3 {
  color: #ffffff;
}

.dashboard-page .header-card {
  background-color: #ffffff;
}

.dashboard-page .b-r-8 {
  background: #977DFE !important;
  color: #ffffff;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  color: #977DFE;
}

.page-wrapper .page-body-wrapper .page-header .row h3 small {
  display: block;
  font-size: 12px;
  margin-top: 7px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #977DFE;
}

label.col-form-label {
  color: #083248;
}

.btn-primary {
  background-color: #083248 !important;
  border-color: #083248 !important;
}

.dashboard-page .header-card h6 {
  font-size: 14px;
  font-weight: 400;
  color: #083248 !important;
  line-height: 24px;
}

.dashboard-page .header-card h3 {
  font-size: 24px;
  font-weight: 400;
  color: #083248 !important;
  letter-spacing: 0.03em;
}

.dashboard-page .btn-order {
  background-color: #977DFE !important;
  border-color: #977DFE !important;
}

.btn-secondary {
  background-color: #083248 !important;
  border-color: #083248 !important;
  margin-top: 30px;
}

.botplaytable .data-table-extensions input {
  float: right;
  padding: 10px;
  margin-top: -50px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li a {
  color: #ffffff;
}

ul.notification-dropdown.onhover-show-div li p {
  margin-left: 30px;
  color: #ffffff !important;
}

li.breadcrumb-item.tab-itme {
  color: #977DFE;
}

.page-main-header .main-header-right .nav-right>ul>li {
  position: relative;
  border-left: none;
  padding: 0 20px;
}

.page-main-header .main-header-right .nav-right>ul>li:nth-child(5) {
  border-left: none;
  border-right: none;
  padding-left: 20px;
}

.delete-modal-dialog .modal-content .cancel-button {
  background-color: #919191 !important;
  border-color: #919191 !important;
  border-radius: 30px;
  margin: 0;
  margin-left: 20px;
}

.products-admin .product-box .front .product-hover ul li .btn .editBtn {
  width: 18px;
  color: #977DFE;
}

.products-admin .product-box .front .product-hover ul li .btn .deleteBtn {
  width: 19px;
  margin-top: 0;
  margin-left: -2px;
  color: #977DFE;
}

.f-w-600 {
  font-weight: 600;
  color: #977DFE;
}

.admin-name p {
  color: #ffffff;
}

ul.notification-dropdown.onhover-show-div li:hover {
  background-color: #083851de !important;
}

ul.notification-dropdown .txt-dark {
  color: #977DFE !important;
}

.product-adding-page .btn-square {
  background-color: #083248 !important;
  border-color: #083248 !important;
  margin-top: 0px;
}

.addbox-form .del-icon {
  margin-top: 0;
}

/******** Date:21-03-22 Theme color changes and design *******/

/******** Date:22-03-22 Style for user login page *******/
.user-login-page .login-card .svg-circle-icon {
  padding: 24px;
  margin: 0 auto;
  border: 2px dashed #977DFE;
  border-radius: 100%;
  height: 130px;
  width: 130px;
  margin-bottom: 40px;
}

.login-tab-page .btn-primary:hover {
  background-color: #093c56 !important;
  border-color: #083248 !important;
}

.react-tabs .nav-tabs .nav-link.react-tabs__tab--selected {
  border-color: transparent transparent transparent !important;
  border-bottom: 2px solid #083248 !important;
  color: #083248;
}

.user-login-page .login-card {
  background-color: #083248 !important;
  color: #ffffff;
}

.authentication-box .container .bg-primary {
  box-shadow: 1px 5px 24px 0 #083248d1;
}

.user-login-page .login-card h3 {
  color: #ffffff;
}

.user-login-page .login-card p {
  color: #ffffff;
}

.user-login-page .authentication-box .slick-dots li.slick-active button:before {
  color: #ffffff;
}

.user-login-page .authentication-box .slick-dots li button:before {
  font-size: 12px;
  color: #ffffff;
}

.user-login-page .loging-card-body {
  padding: 105px 70px;
  background-color: transparent;
}

/******** Date:22-03-22 Style for user login page *******/
.product-adding-page .radio_animated:before {
  background: #083248;
}

.product-adding-page .radio_animated:after {
  background: #ffffff;
  border: 2px solid #083248;
}

.user-status table tbody tr td .image-sm-size img {
  width: 41px;
  height: 41px;
}

.profile-details .user-email {
  color: #083248;
}

.products-admin .product-box .img-wrapper {
  position: relative;
  padding: 20px;
}

.products-admin .product-box {
  min-height: 400px;
  height: 100%;
  padding: 0;
  margin-bottom: 30px;
  box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%);
  /* background-image: -webkit-linear-gradient(28deg,#977DFE,#ab6b10); */
  border: 1px solid #e0e0e0;
}

.products-admin .product-box .card-bg {
  border-top: 1px solid #e0e0e0;
}

.products-admin .product-detail .title-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.product-detail {
  padding: 20px;
  color: #083248;
  text-transform: capitalize;
  height: 1px;
}

.products-admin .product-box .product-detail h6 {
  color: #083248;
  text-transform: capitalize;
  font-size: 20px;
}

.products-admin .product-box .product-detail h4 {
  color: #977DFE !important;
  font-size: 23px;
}

.products-admin .product-box .front .product-hover {
  position: absolute;
  z-index: 1;
}

.submit-btns {
  justify-content: space-between;
}

.submit-btns-inner {
  justify-content: flex-start;
}

.submit-btns-inner button:last-child {
  margin-left: 10px;
}

.submit-btns-inner button:first-child {
  margin-right: 10px;
}

.submit-btns button span {
  background: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  color: #083248;
  margin: 0 3px;
}

.css-26l3qy-menu {
  background-color: hsl(200deg 79% 11%) !important;
}

.css-1n7v3ny-option {
  color: #062332 !important;
  background: #fff !important;
  border: 2px solid #062332 !important;
}

.product-adding-page .d-block {
  display: block !important;
  color: #083248;
}

.product-adding-page .dropzone {
  padding: 10px 10px;
}

.product-adding-page .upload-img-preview {
  margin-bottom: 10px;
}

.addbox-form .search_boxes_section {
  margin-top: 40px;
}

.search_boxes_section .search-section {
  padding: 10px 0px;
}

.products-admin .product-detail {
  border-top: 1px solid #e0e0e0;
}

.search_boxes_section .searched-content-section {
  padding: 0 15px;
}

/************** 1-April-22 style for profile icon and Edit profile design **********/
.profile-details .profile_image {
  position: relative;
  width: 90px;
  margin: 0 auto;
}

.profile-details input#imageUpload {
  display: none;
}

.profile-details .edit-avtr {
  position: relative;
}

.profile-details .edit-avtr input+label {
  background-image: -webkit-linear-gradient(135deg, #8e8e8e, #b5b5b5);
  border-radius: 50%;
  box-shadow: 0 19px 56px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 25px;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;
  width: 25px;
  position: absolute;
  bottom: 33px;
  left: 58px;
}

.profile-details .edit-avtr input+label:after {
  color: #fff;
  content: "\f030";
  font-family: FontAwesome;
  font-weight: 900;
  left: 0;
  margin: auto;
  position: absolute;
  right: -1px;
  text-align: center;
  top: 4px;
  font-size: 12px;
}

.profile_pages .profile_table input[type="text"] {
  width: 300px;
  height: 35px;
}

.profile_pages .profile_table input:focus {
  outline-color: #ced4da;
}

.profile_pages .edit_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile_pages .edit_btn i.fa.fa-pencil-square-o {
  margin-right: 50px;
  font-size: 22px !important;
  cursor: pointer;
  color: #083248 !important;
}

.profile_pages .edit_btn i.fa.fa-pencil-square-o:hover {
  color: #977DFE !important;
}

.profile_pages .edit_btn span {
  margin-left: 3px;
  text-transform: capitalize;
  font-size: 17px;
}

.profile_pages .profile_table .form-control:disabled {
  background-color: #e9ecef00;
  opacity: 1;
  border: none;
}

/************** 1-April-22 style for profile icon and Edit profile design **********/

.product-list-page .pro_contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
}

.product-list-page .price_legen p {
  font-size: 14px;
}

.products-admin .product-box .product-detail h6 {
  text-align: left;
}

.action-btn .dropdown button {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
}

.tolower {
  text-transform: lowercase;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

/************** 6-April-22 style for popup **********/

.delete-modal-dialog .add_title {
  color: #083248;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 20px;
}

.action_btn_dot .dropdown-item:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #073148;
  padding-top: 8px;
}

.delete_modal_dialog .form__group {
  width: 60%;
}

/************** 6-April-22 style for popup **********/

.action_btn_dot .dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 15px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 14px;
}

.action_btn_dot .action.client i {
  margin-right: 10px !important;
  font-size: 17px;
  color: #7c7878 !important;
}

.action_btn_dot .dropdown-item a:hover {
  color: #fff !important;
}

/********* Styel for user profile details ************/
.bal_card .bal__status {
  background: #073148;
  padding: 10px 15px;
  max-width: 170px;
  width: 100%;
  border-radius: 8px;
  margin: 0 15px;
}

.bal_card .bal__status h2 {
  color: #fff;
  color: #fff;
  margin-bottom: 0;
}

/********* Styel for user profile details ************/

.d-block.track-input {
  position: relative;
  padding-left: 30px;
  display: flex !important;
  align-items: flex-end !important;
}

.track-inp {
  color: #083248;
}

.track-inp input {
  margin-right: 5px;
}

.d-block.track-input input {
  margin-left: -30px !important;
  height: 20px;
  width: 20px;
}

.user__profile__card .data-table-extensions input.filter-text {
  border: 1px solid #ddd !important;
  outline: 1px;
  border-radius: 0.25rem;
}

.user__profile__card .data-table-extensions input {
  float: right;
  padding: 10px;
  margin-top: 0;
}

/********* Date: 09/04/2022 Style for user info ************/
.user__profile__card .section {
  padding: 100px 0;
  position: relative;
}

.user__profile__card .gray-bg {
  background-color: #f5f5f5;
}

.user__profile__card .about-avatar {
  max-width: 150px;
  width: 100%;
  border: 2px solid #073148;
  border-radius: 5px;
  overflow: hidden;
  object-fit: contain;
  padding: 3px;
  margin: 0 70px;
}

.user__profile__card img {
  max-width: 100%;
}

.user__profile__card img {
  vertical-align: middle;
  border-style: none;
}

/* About Me 
---------------------*/
.about-text h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 6px;
}

@media (max-width: 767px) {
  .user__profile__card .about-text h3 {
    font-size: 35px;
  }
}

.user__profile__card .about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .user__profile__card .about-text h6 {
    font-size: 18px;
  }
}

.user__profile__card .about-text p {
  font-size: 18px;
  max-width: 450px;
}

.user__profile__card .about-text p mark {
  font-weight: 600;
  color: #20247b;
}

.user__profile__card .about-list {
  padding-top: 10px;
  padding-bottom: 30px;
}

.user__profile__card .about-list .media {
  padding: 5px 0;
}

.user__profile__card .about-list label {
  color: #073148;
  font-weight: 600;
  width: 88px;
  margin: 0;
  position: relative;
}

.user__profile__card .about-list label:after {
  content: ":";
  position: absolute;
  top: -6px;
  bottom: 0;
  right: 8px;
  font-size: 20px;
  margin: auto;
  color: #073148;
}

.user__profile__card .about-list p {
  margin: 0;
  font-size: 15px;
}

@media (max-width: 991px) {
  .user__profile__card .about-avatar {
    margin-top: 30px;
  }
}

.user__profile__card .about-section .counter {
  padding: 22px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px -1px 20px 3px rgb(31 45 61 / 0%);
}

.user__profile__card .about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}

.user__profile__card .about-section .counter .count {
  font-weight: 700;
  color: #073148;
  margin: 0 0 5px;
}

.user__profile__card .about-section .counter p {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

.user__profile__card mark {
  background-image: linear-gradient(rgba(252, 83, 86, 0.6),
      rgba(252, 83, 86, 0.6));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

.user__profile__card .theme-color {
  color: #e89b32;
}

.user__profile__card .dark-color {
  color: #073148;
}

.delete-modal-dialog .check__modal {
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-right: 25px;
}

.delete-modal-dialog .check_box label {
  font-size: 18px;
  color: #041924;
}

.delete-modal-dialog .check_box.form-group {
  font-size: 18px;
  color: #041924;
  padding: 0 35px;
}

.delete-modal-dialog input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 15px;
  height: 16px;
}

.delete-modal-dialog .chck {
  margin-left: 9px;
  margin-top: 6px;
}

.search__section .choose-odds-content div {
  border-right: 1px solid #cecece !important;
}

/* .search__section  .play__num {
  width: 50%;
} */
.search__section .play__num span {
  font-size: 16px;
  width: 50%;
}

.search__section .price span {
  font-size: 16px;
}

.search__section .choose-odds-content {
  margin-bottom: 0;
  justify-content: space-around;
}

.search__section .choose-odds-content div {
  border-right: none !important;
}

/********* Date: 09/04/2022 Style for user info ************/
.box-input-color {
  padding-top: 10px;
}

.box-input-color li {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50px;
  margin: 2px;
}

/********* Date: 19/04/2022 Style special tag ************/
.special_tag .tag {
  background: #073148;
  padding: 2px 10px;
  text-transform: capitalize;
  border-radius: 5px;
  margin: 0px 5px;
  margin-top: 10px;
  letter-spacing: 1px;
}

/********* Date: 09/04/2022 Style for user info ************/

.export-csv {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.botlist-page .data-table-extensions-filter {
  position: absolute;
}

.ps__rail-y {
  display: none;
}

/********* Date: 19/04/2022 Style special tag ************/

button.load___more {
  padding: 10px 50px;
}

.hide_show {
  color: #041924;
}

.addbox-form button.perc_btn {
  margin-bottom: 10px;
}

.filter__input .data-table-extensions input {
  float: right;
  padding: 10px;
  margin-top: 15px;
}

/***** battle page *********/
.create-battle-section {
  display: flex;
  align-items: center;
}

.create-battle-section label {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

.addbox-form .choose-odds .increment {
  border: none !important;
  padding: 0px 10px !important;
  min-height: auto;
}

.addbox-form .choose-odds .increment i {
  font-size: 20px;
  cursor: pointer;
  margin: 0px 10px;
}

.addbox-form .choose-odds .increment span {
  font-size: 16px;
  padding: 0px 8px;
}

.addbox-form h4.choose-boxes-heading {
  color: #083248;
  padding: 10px 0px 0px;
  font-size: 22px;
}

.rdt_Table {
  min-height: 500px;
}

.bottom-product-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* admin-permission */

.admin-permission .form-check.form-check-inline {
  padding: 10px 0px;
}

.admin-permission .transactionManage {
  margin: 5px 0px 30px 0px;
  background: #f7f5f4;
  padding: 10px;
  border-radius: 5px;
}

.admin-permission .transactionManage .form-check.form-check-inline {
  width: 23%;
}

.admin-permission .transactionManage .form-check.form-check-inline {
  width: 23%;
}

.admin-permission .transactionManage {
  margin: 5px 0px 30px 0px;
  background: #f7f5f4;
  padding: 10px;
  border-radius: 5px;
}

.admin-permission .transactionManage .form-check.form-check-inline {
  width: 23%;
}

.activity-log-table .rdt_Table * {
  white-space: inherit !important;
  overflow: auto !important;
}

.activity-log-table .action-btn * {
  overflow: hidden !important;
}

.order-process-popup {
  max-width: 650px;
  width: 100%;
}

.selected-items {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.selected-items p {
  line-height: 0px;
  margin-bottom: 0px;
}

.selected-items .d-flex {
  align-items: center;
  margin-left: 0px;
  width: 100%;
}

.selected-items-detail {
  width: 100%;
  text-align: left;
}

.order-process-popup .admin-input {
  width: 100%;
  text-align: left;
}

.order-process-popup .modal-body {
  padding: 10px 50px;
  margin-top: 10px;
}

.selected-items-detail h4 {
  color: #555555;
  font-weight: 600;
  margin-bottom: 15px;
}

.selected-items-detail .selected-items p {
  margin: 0px 10px;
}

.selected-items-detail img {
  margin: 0px 10px;
}

.order-detail-list {
  background: #e1e1e13d;
  padding: 25px;
  border-radius: 5px;
  color: black;
  box-shadow: 0px 0px 20px 0px white;
  border: 1px solid #b8b8b8;
  margin-bottom: 20px;
}

.ordered-items img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.ordered-items {
  text-align: center;
  width: 30%;
  margin: 0px 2px 0px;
  background: #f8f8f9;
  padding: 5px;
}

.ordered-items p {
  margin-bottom: 0px;
  font-size: 12px;
  padding: 5px 0px;
}

.order-detail-list .user-name h6 {
  font-size: 22px;
  font-weight: 600;
}

.order-detail-list .user-name p {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  color: #ff9707;
}

.order-detail-list .user-name p span {
  color: #083248;
  font-weight: 400;
  font-size: 15px;
}

.order-detail-list h4 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 25px;
  color: #083248;
}

.ordered-detail-list {
  display: flex;
  justify-content: space-between;
}

.left-order-details {
  width: 50%;
}

.right-order-section {
  width: 50%;
}

.ordered-detail-list h6 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.order-invoice-template table tr td {
  padding: 5px 20px !important;
  text-align: left;
}

.order-invoice-template {
  margin: 50px auto;
  width: 750px;
  background-color: white;
  /* border: 1px solid #ebebeb; */
  padding: 10px 0px;
  min-height: 600px;
}

/* .order-invoice-template img {
  height: 60px;
} */
.order-invoice-template h1 {
  font-size: 60px;
  color: #0a3b6c;
  font-weight: 700 !important;
}

.order-invoice-template tr td {
  color: #333;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.order-invoice-template tr td p {
  margin: 0;
}

.order-invoice-template tr td h6 {
  font-weight: 700 !important;
  color: #0a3b6c;
  font-size: 16px;
}

.order-invoice-template table thead td {
  font-size: 60px;
  color: #0a3b6c;
  font-size: 16px;
  font-weight: 700 !important;
}

.order-invoice-template .address-section h6 {
  margin: 0;
}

.order-invoice-template table h5 {
  color: #333;
  font-weight: 800 !important;
}

.order-invoice-template .invoice-no {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-page .category-search {
  /* margin: 0; */
  margin-bottom: 25px;
  margin-left: 0;
}

.custom-date {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
  row-gap: 20px;
}

.custom-date-content {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
}

.custom-date-content label {
  color: #000;
}

/* .deposit-table .eUUHpm div {
  white-space: unset !important;
  overflow: unset !important;
} */

@media print {

  .page-sidebar,
  .page-main-header,
  .main-header-left,
  .main-header-right,
  .admin-order-invoice,
  .footer,
  .custom-theme {
    display: none !important;
  }

  .order-invoice-template {
    display: block;
    /* width: 100% !important; */
    max-width: 100% !important;
  }

  .page-wrapper .page-body-wrapper .page-sidebar~.page-body {
    margin-left: 0px !important;
  }
}

.card-body p:first-child {
  text-transform: uppercase;
  font-weight: 700;
  text-wrap: nowrap;
}

.card-header h6:first-child {
  text-transform: uppercase;
  font-weight: 700;
}

.prod-price {
  padding-right: 108px !important;
  padding-left: 20px !important;
}

.prod-name {
  padding-right: 61px !important;
  padding-left: 110px !important;
}

.search-section-header {
  background-color: #2a2d35;
  margin: 0px 6px;
  display: flex;
  border-radius: 7px;
  flex-direction: column;

  color: #fff;
}

.choose-odds-contentt div {
  border-right: 1px solid #cecece;
  padding: 15px;
  display: inline-flex;
  align-content: center;
  align-items: center;
  min-height: 40px;
}

.prod-chance {
  padding-left: 126px !important;
  border-right: none !important;
}

.prod-price-sec {
  padding-left: 100px;
  border-right: none !important;
}

.note-result {
  background-color: #ffc0cb;
  height: 200px;
  padding: 25px;
  border-radius: 5px;
}

.col-form-label span {
  font-size: 24px !important;
}

.price_legen h4 {
  text-wrap: nowrap;
}

.modal-header button {
  width: 25px !important;
}

.upload-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 4px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.itemselected {
  border: 2px solid #073248;
}

.pro_name h6 {
  text-wrap: wrap !important;
}

.admin-wallet h5 {
  color: black !important;
  font-size: 14px;
  font-weight: 500;
}



/* Pagination In Add Box Page */
.search-pagination li {
  padding: 5px 8px;
  margin: 0;
}

/* .search-pagination li.selected {
        text-decoration: underline;
    } */
.search-pagination li.selected a {
  font-weight: bold;
  color: #fff !important;
  background: #083248 !important;
}

.search-pagination li a {
  color: #083248 !important;
  border: 2px solid #083248;
  width: 25px;
  height: 25px;
  line-height: 16px;
  font-size: 16px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.search-pagination li.previous,
.search-pagination li.nex {
  padding: 5px 0;
}

.search-pagination li.previous a,
.search-pagination li.next a {
  font-family: "FontAwesome" !important;
  background: #ffffff;
  border: none;
  color: #7c7c7c !important;
  /* padding: 2px 5px; */
  line-height: 18px;
  font-size: 18px;
  border-radius: 50%;
  display: inline-flex;

  align-items: center;
  justify-content: center;
}

.search-pagination li.previous a:after {
  content: "\f053";
}

.search-pagination li.next a:after {
  content: "\f054";
}

.sorting-dropdown {
  position: absolute;
  left: 69%;
  top: 124px;
}

.custom-dropdown {
  height: 41px !important;
  width: 215px !important;
  border: 1px solid #ddd !important;
  border-radius: 0.25rem !important;
  outline: 1px !important;
  color: #757582 !important;
  padding-left: 5px !important;
  font-size: 14px !important;
}

.temp-title h2 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
}

.temp-title span {
  color: #977DFE;
}

.height-equal .card-body{
  height: 500px;
  overflow-y: auto;
}

.height-equal .card-body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.height-equal .card-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.height-equal .card-body::-webkit-scrollbar-thumb {
  background: #977DFE;
  border-radius: 10px; 
}

/* Handle on hover */
.height-equal .card-body::-webkit-scrollbar-thumb:hover {
  background: #977DFE; 
}

.spinner-loader{
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}