.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 99999;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader::before {
  content: "";
  position: absolute;
  height: 100vh;
  width: 100%;
  background: #000000e3;
}
.loader .spinner-grow {
  height: 50px;
  width: 50px;
  color: #977DFE !important;
}
