[class^='icon-'],
[class*=' icon-'] {
	@include icon-themify-icons();
}

[class^='icon-']:before,
[class*=' icon-']:before {
	text-decoration: inherit;
	display: inline-block;
	speak: none;
}

/* makes the font 33% larger relative to the icon container */
.icon-large:before {
	vertical-align: -10%;
	font-size: calc(4em / 3);
}

/* makes sure icons active on rollover in links */
a {
	[class^='icon-'],
	[class*=' icon-'] {
		display: inline;
	}
}

/* increased font size for icon-large */
[class^='icon-'],
[class*=' icon-'] {
	&.icon-fixed-width {
		display: inline-block;
		width: calc(16em / 14);
		text-align: right;
		padding-right: (4em/14);
		&.icon-large {
			width: (20em/14);
		}
	}
}

.icons-ul {
	margin-left: $icons-li-width;
	list-style-type: none;

	> li {
		position: relative;
	}

	.icon-li {
		position: absolute;
		left: -$icons-li-width;
		width: $icons-li-width;
		text-align: center;
		line-height: inherit;
	}
}

// allows usage of the hide class directly on font awesome icons
[class^='icon-'],
[class*=' icon-'] {
	&.hide {
		display: none;
	}
}

.icon-muted {
	color: $iconMuted;
}
.icon-light {
	color: $iconLight;
}
.icon-dark {
	color: $iconDark;
}

// Icon Borders
// -------------------------

.icon-border {
	border: solid 1px $borderColor;
	padding: 0.2em 0.25em 0.15em;
	@include border-radius(3px);
}

// Icon Sizes
// -------------------------

.icon-2x {
	font-size: 2em;
	&.icon-border {
		border-width: 2px;
		@include border-radius(4px);
	}
}
.icon-3x {
	font-size: 3em;
	&.icon-border {
		border-width: 3px;
		@include border-radius(5px);
	}
}
.icon-4x {
	font-size: 4em;
	&.icon-border {
		border-width: 4px;
		@include border-radius(6px);
	}
}

.icon-5x {
	font-size: 5em;
	&.icon-border {
		border-width: 5px;
		@include border-radius(7px);
	}
}

// Floats & Margins
// -------------------------

// Quick floats
.pull-right {
	float: right;
}
.pull-left {
	float: left;
}

[class^='icon-'],
[class*=' icon-'] {
	&.pull-left {
		margin-right: 0.3em;
	}
	&.pull-right {
		margin-left: 0.3em;
	}
}
