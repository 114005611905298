@import "~bootstrap-scss/bootstrap.scss";
@import 'theme/variables';
@import 'theme/admin_style';
@import 'theme/rtl';
@import 'flag-icon.scss';
@import 'themify.scss';
@import 'icoicon/icons';
@import 'font-awesome.scss';

@font-face {
    font-family: "Biko";
    src: url(../fonts//biko/Biko_Regular.otf);
    // src: url(../fonts//biko/Biko_Bold.otf);
  }

  body *{
    font-family: Biko !important;
  }