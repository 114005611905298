.card-body-header {
    display: flex;
    justify-content: space-between;
}

.card-body-header .country-filter {
    border: 1px solid #ddd !important;
    outline: 1px;
    border-radius: 0.25rem;
    padding: 10px;
}

.country-edit {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
}

.table-head,
.table-body {
    text-align: center;
}

.close-button {
    text-align: right;
    margin: 14px;
}

.close-button button {
    background: none !important;
    border: none;
}

.card-body-content {
    text-align: center;
}

.input-fields select {
    width: 90%;
    padding: 7px 14px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #2A2D35;
}

.input-fields select::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.input-fields select::-webkit-scrollbar-track {
    background-color: rgba(91, 89, 89, 0.467);
}

.input-fields select::-webkit-scrollbar-thumb {
    background-color: #083248;
}

.input-fields select:focus {
    outline: none;
}

.action-radiobuttons {
    display: flex;
    gap: 30px;
    margin-left: 25px;
}

.action-radiobuttons label {
    color: black !important;
}

.add-button .add-btn {
    width: 100%;
}

.country-flag,
.country-name {
    width: 200px;
    margin: auto;
}

.country-name p {
    text-align: center;
    padding-top: 10px;
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
}

.allow {
    color: green;
}

.block {
    color: red;
}