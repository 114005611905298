.tiphead h5 {
    color: black;
    text-align: center;
    justify-content: center;
    font-weight: 600;
}

.completed {
    color: green;
}

.processing {
    color: red;
}

.custom-pagination {
    display: flex;
    float: right;
    align-items: center;
    gap: 10px;
}

.pagination-info,
.select-page-item {
    color: rgba(0, 0, 0, 0.54) !important;
}

.page-icon {
    background: none;
    text-decoration: none;
    border: none;
    color: rgba(0, 0, 0, 0.54) !important;
}

.select-number {
    color: rgba(0, 0, 0, 0.54) !important;
    border: none;
    cursor: pointer;
}
.select-pages{
    display: flex;
    gap: 5px;
    margin-right: 10px;
}