/**=====================
    Admin CSS Start
==========================**/
.card-left {
  z-index: 1;
}
.checkbox-space {
  padding: 0;
}
.translation-list {
  .ReactTable {
    .rt-thead {
      .rt-tr {
        text-align: left;
      }
    }
  }
}
.react-datepicker__input-container{
  input {
    border-radius: 0.25rem;
    padding: 0.48rem 0.75rem;
    border: 1px solid #ced4da;
  }
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  background-color:$primary-color !important;
}
.coupon-list-delete {
  .ReactTable {
    .rt-table {
      .rt-tr {
        .rt-th {
          &:last-child {
            .rt-resizable-header-content {
              padding: 10px !important;
            }
          }
        }
      }
    }
  }
}

.rt-th , .rt-resizable-header{
  width: 0;
}
.rt-td{
  width: 0;
  div{
    padding: 5px;
  }
}
.form-label-center {
  .form-group {
    label {
      margin-top: 8px;
    }
    .form-group {
      margin-bottom: 0;
    }
  }
}
.tab-coupon {
  li {
    color: $primary-color;
  }
}
.styles_modal__gNwvD {
  padding: 0;
  button {
    svg {
      width: 18px;
    }
  }
}
.product-page-details {
  h2 {
    margin-bottom: 5px !important;
  }
  label {
    margin-bottom: 0 !important;
  }
  .product-price {
    h3 {
      color: $dark-font;
    }
  }
}
.product-slider {
  margin-bottom: 20px;
}
.small-slick {
  .slick-slide {
    &.slick-current {
      div {
        .item {
           border: 1px solid $primary-color;
        }
      }
    }
  }
}
.ReactTable .rt-tr{
  align-items: center;
}
.rt-resizable-header-content{
  padding: 18px;
  background: #f8f8f9;
}
.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 20px 0 0;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid $primary-color;
    border-top-style: none;
    border-right-style: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
  }
  &:checked:before {
    transform: rotate(-45deg) scale(1, 1);
  }
}
.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: $primary-color;
    border-radius: 50%;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: $white;
    border: 2px solid $light-gray;
    border-radius: 50%;
  }
  &:checked:before {
    transform: scale(1, 1);
  }
}
a {
  &:hover {
    color: $primary-color;
  }
}
.btn-popup {
  margin-bottom: 30px;
}
// category
.add-product {
  .bigImg{
    img {
      width: 500px;
    }
  }
  ul {
    li {
      display: flex;
      margin-bottom: 15px;
      .box-input-file {
        width: 50px;
        height: 50px;
        background-color: #dddddd;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        .upload {
          position: absolute;
          width: 70px;
          left: 0;
          right: 0;
          opacity: 0;
        }
      }
    }
  }
}
.file-upload-product{
  .uploadImg{
    width: 50px !important;
    height: 50px !important;
  }
}
.dropzone{
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding: 50px;
  border: 2px dashed $theme-deafult;
  border-radius: 15px;
  border-image: none;
  background: transparent;
  box-sizing: border-box;
  min-height: 150px;
  position: relative;
  i{
    font-size: 50px;
  color: $theme-deafult;
  }
  .dzu-input{
    border: 1px solid #ddd;
    padding: 6px;
    margin-left: 15px;
  }
  .dzu-previewStatusContainer, .dzu-submitButtonContainer {
    display: none;
  }
  .dzu-previewImage {
    width: 75px;
  }
}
.digital-add {

  .col-form-label {
    font-family: $font-nunito;
  }
  .form-control {
    font-size: 14px;
  }
  .form-group {
    .radio_animated {
      margin-right: 16px;
    }
  }
  textarea {
    width: 100%;
  }
}
.add-product-form {
  .form-group {
    align-items: center;
    label {
      font-size: 16px;
      font-weight: 600;
    }
    &:last-child {
      align-items: end;
    }
    .form-control {
      font-size: 14px;
    }
  }
  fieldset {
    padding-left: 15px;
    border: none;
  }
  .qty-box {
    width: 15%;
    .touchspin {
      text-align: center;
    }
    .bootstrap-touchspin-prefix {
      display: none;
    }
    .bootstrap-touchspin-postfix {
      display: none;
    }
    .input-group {
      justify-content: flex-start;
      button {
        padding: 12px;
        i {
          font-weight: 900;
          color: #222222;
        }
      }
      .btn-primary {
        background-color: $transparent-color !important;
        border: 1px solid #ddd !important;
        &.bootstrap-touchspin-down {
          border-right: none !important;
        }
      }
      .input-group-append {
        margin-left: 0;
        .btn {
          border-left: none !important;
        }
      }
    }
  }
}
.chart-block canvas {
  width: 100%;
}
.sales-carousel {
  .value-graph {
    h3 {
      color: $theme-body-font-color;
      font-weight: 600;
    }
  }
  .media {
    .small-box {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.small-chartjs {
  canvas {
    width: 100% !important;
    height: 60px !important;
  }
}
.order-graph {
  h6 {
    color: $theme-body-font-color;
    font-family: $font-nunito;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .order-graph-bottom {
    margin-top: 30px;
    h6 {
      color: $light-text;
      margin-left: 15px;
      margin-right: 30px;
      span {
        color: $theme-body-font-color;
      }
    }
    .media {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @each $shape-name, $shape-color in (primary, $primary-color), (secondary, $secondary-color),
        (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
        (dark, $dark-color), (warning, $warning-color)
      {
        .order-shape-#{$shape-name} {
          background-color: $shape-color;
          width: 18px;
          height: 8px;
          margin-top: 7px;
          border-radius: 4px;
        }
      }
    }
    @each $order-name, $order-color in (primary, $primary-color), (secondary, $secondary-color),
      (success, $success-color), (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .order-color-#{$order-name} {
        background-color: $order-color;
        width: 8px;
        height: 8px;
        margin-top: 7px;
        border-radius: 2px;
      }
    }
  }
}
.datepickers-container {
  top: -86px;
}
.datepicker--nav {
  color: $theme-deafult;
}
.datepicker--nav-action {
  color: $theme-deafult;
  background-color: $theme-deafult;
}
.datepicker--nav-action:hover {
  color: $theme-deafult;
}
.datepicker--nav-title i {
  color: $theme-deafult;
}
.table {
  thead {
    th {
      border-bottom: 2px solid $light-gray;
    }
  }
}
.authentication-box{
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .slick-slider {
    margin-bottom: 30px;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary-color;
  }
  .back-btn{
    float: right;
    margin: 0;
    font-size: 14px;
    position: relative;
    padding-left: 60px !important;
    svg{
      position: absolute;
      left: 30px;
      height: 18px;
    }
  }
  .row{
    align-items: center;
  }
  .tab2-card {
    .nav-tabs {
      .nav-link{
        font-size: 18px;
        svg {
          width: 20px;
          vertical-align: sub;
          margin-right: 5px;
        }
      }
    }

  }
  .btn-primary{
    border-radius: 25px;
    margin-top: 12px;
    font-weight: 400;
    padding: 11px 45px;
  }
  .form-footer{
    position: relative;
    padding-top: 25px;
    border-top: 1px solid #f3f3f3;
    margin-top: 25px;
    >span{
      position: absolute;
      top: -11px;
      padding-right: 20px;
      background-color: #fff;
      color: #909090;
    }
    .social{
      margin-bottom: 0;
      border-bottom: 0;
      li{
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ff8084;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        text-align: center;
        display: inline-block;
      }
    }
  }
  .slick-dots {
    li{
      &.slick-active {
        button:before{
          color: $white;
        }
      }
      button{
        &:before{
          font-size: 12px;
          color: $white;
        }
      }
    }
  }
  .container{
    max-width: 900px;
    .bg-primary{
      padding: 50px;
      background-image: url("../../images/dashboard/login-bg.png");
      background-position: center;
      box-shadow: 1px 5px 24px 0 rgba(255, 128, 132, 0.8);
    }
    .form-group {
      margin-bottom: 1.5rem;
    }
    .svg-icon{
      padding: 24px;
      margin: 0 auto;
      border: 2px dashed $white;
      border-radius: 100%;
      height: 130px;
      width: 130px;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    p{
      color: rgba($white, 0.9);
      font-size: 15px;
      line-height: 2;
      text-align: center;
    }
    h3{
      color: $white;
      font-weight: 600;
      text-align: center;
    }
  }
}
.auth-form{
  .form-control {
    border-radius: 25px;
    padding: 9px 25px;
    border: 1px solid #eaeaea;
  }
}
.switch-sm {
  .switch {
    width: 25px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      .sidebar-menu {
        li {
          width: 100%;
        }
      }
    }
    footer {
      p {
        line-height: 1.7;
      }
    }
    .page-header {
      .row {
        h3 {
          color: #000;
        }
      }
    }
  }
}
.widget-cards {
  border-radius: 25px !important;
}
.static-top-widget {
  div.align-self-center {
    svg {
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }
  .media-body {
    align-self: center !important;
    h3 {
      font-family: $font-work-sans, $font-serif;
      color: $white;
      small {
        font-size: 11px;
      }
    }
  }
  .icons-widgets {
    .text-center {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.flot-chart-container {
  height: 400px;
}
.user-status {
  table {
    tbody {
      tr {
        td {
          vertical-align: middle;
          .d-inline-block {
            margin-top: 11px;
          }
          .image-sm-size {
            img {
              width: 41px;
            }
          }
        }
        &:last-child td {
          padding-bottom: 0;
        }
      }
    }
    thead {
      tr {
        th {
          border-top: 0;
          font-size: 16px;
          color: $dark-color;
          font-weight: 600;
          padding-top: 0;
        }
      }
    }
  }
}

.card-block {
  .table-responsive {
    .table {
      caption {
        padding-left: 10px;
      }
    }
    .table-bordered {
      td {
        vertical-align: middle;
      }
    }
  }
  .table-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
// product list
.products-admin {
  .product-box {
    padding: 20px;
    .img-wrapper {
      position: relative;
    }
    .lable-block {
      .lable3 {
        border-radius: 100%;
        background-color: $theme-deafult;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        padding: 12px 6px;
        text-transform: uppercase;
        color: $white;
        top: 7px;
        left: 7px;
        z-index: 1;
      }
      .lable4 {
        position: absolute;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        top: 7px;
        right: 7px;
        letter-spacing: 0.1em;
        z-index: 1;
      }
    }
    .product-detail {
      .rating {
        margin-top: 15px !important;
        i {
          padding-right: 5px;
          &:nth-child(-n + 4) {
            color: $star-yellow;
          }
          &:last-child {
            color: $round-border;
          }
        }
      }
      h6 {
        line-height: 1;
        margin-bottom: 0;
        padding-top: 2px;
        padding-bottom: 5px;
        transition: all 0.5s ease;
        font-size: 16px;
        color: $grey;
        text-align:center;
      }
      h4 {
        font-size: 18px;
        color: $dark-font;
        font-weight: 700;
        margin-bottom: 0;
        transition: all 0.5s ease;
        color: $dark-font !important;
      }
      del {
        font-size: 14px;
        color: $font-grey;
        font-weight: 400;
      }
      .color-variant {
        padding-top: 15px;
        li {
          display: inline-block;
          height: 20px;
          width: 20px;
          border-radius: 100%;
          margin-right: 5px;
          transition: all 0.1s ease;
          cursor: pointer;
        }
      }
    }
    .front {
      position: relative;
      .product-hover {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0.7);
        opacity: 0;
        border-radius: 100%;
        transform: scale(0);
        transition: all 0.3s ease;
        ul {
          li {
            display: inline-block;
            box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
            padding: 9px 14px;
            background-color: $white;
            font-size: 18px;
            border-radius: 100%;
            line-height: 1.6;
            height: 45px;
            width: 45px;
            margin: 0 3px;
            .btn {
              padding: 0;
              background-color: $transparent-color;
              .editBtn {
                width: 18px;
              }
              svg {
                stroke-width: 1;
              }
              .deleteBtn {
                width: 19px;
                margin-top: 0;
                margin-left: -2px;
              }
            }
          }
        }
      }
      &:hover {
        .product-hover {
          opacity: 1;
          border-radius: 0%;
          transform: scale(1);
          transition: all 0.3s ease;
        }
      }
    }
  }
}
// product Detail

.product-right {
  .timer {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    display: inline-block;
    margin-top: 10px;
    background-color: #f7f7f7;
    p {
      color: #222222;
      font-size: 18px;
    }
    span {
      width: 70px;
      display: inline-block;
      .padding-l {
        padding-left: 22px;
        display: inline;
      }
    }
    .timer-cal {
      font-size: 12px;
      color: #777777;
    }
  }
  .size-box {
    margin-top: 10px;
    margin-bottom: 10px;
    ul {
      li {

        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid #f7f7f7;
        text-align: center;
        display: inline-block;
        &.active {
          background-color: #f7f7f7 !important;
        }
        a {
          color: #222222 !important;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
  .color-variant {
    margin-bottom: 10px !important;
    li {
      height: 30px;
      width: 30px;
      cursor: pointer;
      display: inline-block;
      border-radius: 100%;
      margin-right: 5px;
      transition: all 0.1s ease;
      vertical-align: middle;
    }
  }
  .product-title {
    color: #222222;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 0;
    a{
      color: $theme-deafult !important;
    }
    p {
      margin-bottom: 0;
      line-height: 1.5em;
    }
    h3 {
      font-size: 26px;
      color: #222222;
      margin-bottom: 15px;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 1.2em;
  }
}
.bg-light0 {
  background-color: $light-grey;
}
.bg-light1 {
  background-color: $pink;
}
.bg-light2 {
  background-color: $blue;
}
// profile
.react-tabs {
  .nav-tabs {
    .nav-link {
      color: $font-color;
      transition: 0.3s;
      cursor: pointer;
      &.react-tabs__tab--selected,
      &:hover, &:focus {
        border-color: $transparent-color $transparent-color $transparent-color !important;
        border-bottom: 2px solid $primary-color !important;
        color: $primary-color;
      }
    }
  }
}
.deactivate-account {
  margin-top: 30px;
  .btn {
    margin-top: 20px;
  }
}
.account-setting {
  h5 {
    margin-bottom: 20px;
  }
}
.profile-card {
  ul {
    margin-bottom: 30px;
    border-bottom: 1px solid $white-4;
    li {
        svg {
          width: 18px;
          vertical-align: middle;
      }
    }
  }
}
.profile-details {
  img {
    margin-bottom: 20px;
  }
  .social {
    margin-top: 15px;
    .btn-showcase {
      margin-bottom: -10px;
      .btn {
        padding: 8px 13px;
        margin: 0 5px 20px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
      }
      .btn-fb {
        background-color: #50598e;
        color: $white;
      }
      .btn-google {
        background-color: #c64e40;
        color: $white;
      }
      .btn-twitter {
        background-color: #6fa2d8;
        color: $white;
      }
    }
  }
}
.project-status {
  margin-top: 20px;
  .media {
    margin-top: 20px;
  }
}
.sm-progress-bar {
  height: 6px;
}
.profile-table {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            min-width: 250px;
          }
        }
      }
    }
    th,
    td {
      border-top: none;
    }
  }
}
// reports
.report-employee {
  .card-header {
    border-bottom: none !important;
  }
  .flot-chart-container {
    height: 323px;
  }
}
.sales-chart {
  height: 307px;
}
.expense-chart {
  #area-chart1 {
    height: 307px;
  }
}
// profile
.profile-card {
  .media {
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
}
// Media
.dropzone {
  .dz-preview {
    box-shadow: 0px 0px 3px $primary-color;
    .dz-error-message {
      color: $primary-color !important;
      background: $transparent-color !important;
      border: 1px solid $primary-color !important;
      &:after {
        border-bottom: 6px solid $primary-color !important;
      }
    }
  }
}
//tabs
.tab-coupon {
  margin-bottom: 30px;
}
.needs-validation {
  .permission-block {
    .attribute-blocks {
      padding-left: 15px;
      .row {
        padding-left: 20px;
      }
      + .attribute-blocks {
        h5 {
          margin-top: 30px;
        }
      }
      h6 {
        border-bottom: 1px solid $light-semi-gray;
        margin-bottom: 15px;
        padding-bottom: 5px;
      }
    }
  }
  h4 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  input {
    font-size: 14px;
  }
  .form-group {
    align-items: center;
    label {
      margin-bottom: 0;
      span {
        color: red;
      }
    }
    .checkbox {
      padding-left: 0;
    }
  }
  .editor-label {
    align-items: end;
  }
  .editor-space {
    padding: 0;
  }
  .checkbox {
    input {
      opacity: 0;
    }
    label {
      display: inline-block;
      position: relative;
      padding-left: 16px;
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 19px;
        height: 19px;
        left: 0;
        margin-left: -16px;
        border: 1px solid #e8ebf2;
        border-radius: 3px;
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      }
    }
    input[type="checkbox"]:checked + label::before {
      font-family: $font-ICO;
      content: "\efab";
      text-align: center;
      line-height: 1;
      font-size: 18px;
    }
  }
  .checkbox-primary {
    input[type="checkbox"]:checked + label::before {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
  .radio-animated {
    label {
      margin-right: 20px;
    }
  }
  textarea {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
// datatable
.badge-warning {
  color: $white;
}
.user-list {
  img {
    border-radius: 100%;
  }
}
/**=====================
  Admin CSS Ends
==========================**/

/**=====================
      Button CSS start
==========================**/
@mixin btn-gradien($deg, $leftcolor, $leftcolor-per, $rightcolor, $rightcolor-per, $font-color) {
  background-image: linear-gradient(to right, $leftcolor 0%, $rightcolor 51%, $leftcolor 100%);
  border: none;
  color: $font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
@mixin btn-squre($border-radius) {
  border-radius: $border-radius + px;
}
@mixin btn-pill($border-radius) {
  border-radius: $border-radius + px;
}
@mixin btn-pill-first($radius) {
  border-radius: $radius + px 0 0 $radius + px;
}
@mixin btn-pill-last($radius) {
  border-radius: 0 $radius + px $radius + px 0;
}

.btn {
  font-size: $btn-font-size;
  padding: $btn-padding;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.btn-lg {
  font-size: $btn-lg-font-size;
}
.btn-sm {
  font-size: $btn-sm-font-size;
}
.btn-xs {
  padding: $btn-xs-padding;
  font-size: $btn-xs-font-size;
}
.large-btn {
  .btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }
  margin-bottom: -20px;
}

// forms //
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .input-air-#{$btn-name} {
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    &:focus {
      border-color: $btn-color;
    }
  }
}

//input background //
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .form-bg-#{$btn-name} {
    background: $btn-color;
    border-color: $btn-color;
    @if ($btn-name == light) {
      color: $dark-color;
    } @else {
      color: $white;
    }
    &:focus {
      border-color: $btn-color;
      background: $btn-color;
      @if ($btn-name == light) {
        color: $dark-color;
      } @else {
        color: $white;
      }
      &:active {
        border-color: $btn-color;
        background: $btn-color;
        @if ($btn-name == light) {
          color: $dark-color;
        } @else {
          color: $white;
        }
      }
    }
  }
}

.btn-warning {
  color: $white;
  &:hover,
  &.disabled {
    color: $white;
  }
}
.btn-primary-gradien {
  @include btn-gradien(60, lighten($primary-color, 8%), 0%, darken($primary-color, 8%), 100%, $white);
  &:focus,
  &:active,
  &.active {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($primary-color, 8%),
      0%,
      darken($primary-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-secondary-gradien {
  @include btn-gradien(60, lighten($secondary-color, 8%), 0%, darken($secondary-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($secondary-color, 8%),
      0%,
      darken($secondary-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-success-gradien {
  @include btn-gradien(60, lighten($success-color, 8%), 0%, darken($success-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($success-color, 8%),
      0%,
      darken($success-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-danger-gradien {
  @include btn-gradien(60, lighten($danger-color, 8%), 0%, darken($danger-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($danger-color, 8%),
      0%,
      darken($danger-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-warning-gradien {
  @include btn-gradien(60, lighten($warning-color, 8%), 0%, darken($warning-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($warning-color, 8%),
      0%,
      darken($warning-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-info-gradien {
  @include btn-gradien(60, lighten($info-color, 8%), 0%, darken($info-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($info-color, 8%),
      0%,
      darken($info-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-light{
  margin-left: 7px;
}
.btn-light-gradien {
  @include btn-gradien(60, lighten($light-color, 8%), 0%, darken($light-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($light-color, 8%),
      0%,
      darken($light-color, 8%),
      100%,
      $white
    ) !important;
  }
}
.btn-dark-gradien {
  @include btn-gradien(60, lighten($dark-color, 8%), 0%, darken($dark-color, 8%), 100%, $white);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(
      to right,
      lighten($dark-color, 8%),
      0%,
      darken($dark-color, 8%),
      100%,
      $white
    ) !important;
  }
}
[class*="-gradien"] {
  &:hover {
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: $white;
  }
}

/**=====================
 Button CSS end
==========================**/

/**=====================
      Card CSS Start
==========================**/

.card {
  margin-bottom: $card-margin-bottom;
  border: $card-border-width;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;
  .card-header {
    background-color: $card-header-bg-color;
    border-bottom: none;
    padding: $card-padding;
    border-bottom: 1px solid $card-border-color;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    h5 {
      font-size: $card-header-font-size;
      margin-bottom: 0;
      text-transform: $card-header-font-transform;
      font-weight: $card-header-font-weight;
      line-height: 24px;
    }
    > span {
      font-size: $card-header-span-size;
      color: $card-header-span-color;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px;
    }
  }
  .card-body {
    padding: $card-padding;
    background-color: $card-body-bg-color;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .card-footer {
    background-color: $card-footer-bg-color;
    border-top: 1px solid $card-border-color;
    padding: $card-padding;
    border-bottom-left-radius: $card-border-radious;
    border-bottom-right-radius: $card-border-radious;
  }
}
.card-header {
  .nav-material {
    margin-bottom: -13px;
  }
}
/**=====================
     Card CSS End
==========================**/

/**=====================
      Footer CSS Start
==========================**/
footer {
  background-color: $footer_bg_color;
  border-top: 1px solid $footer_top_bgr_color;
  padding: 15px;
  bottom: 0;
  left: 0;
  a {
    font-weight: 600;
  }
}
.page-wrapper {
  .page-body-wrapper {
    footer {
      // margin-left: 255px;
      transition: all 0.3s ease;
      bottom: 0;
      z-index: 8;
      p {
        color: rgb(165, 165, 165);
        i {
          color: $danger-color;
          margin-left: 5px;
        }
      }
    }
  }
}
/**=====================
      Footer CSS Ends
==========================**/

/**=====================
      General CSS Start
==========================**/
//Custom css Start
h1 {
  font-size: 60px;
  color: $dark-font;
  font-weight: 700;
  text-transform: uppercase;
  span {
    font-size: 107px;
    font-weight: 700;
    color: $primary-color;
  }
}

h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  color: $black;
  line-height: 1;
  letter-spacing: 0.02em;
 }

h3 {
  font-size: 24px;
  font-weight: 400;
  color: $grey;
  letter-spacing: 0.03em;
 }

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1; }

del {
  font-size: 14px;
  color: $font-grey;
  font-weight: 400;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: $dark-font;
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: $grey;
  line-height: 24px; }

body {
  background-color: $body-bg-color;
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-work-sans, $font-serif;
  color: $theme-body-font-color;
  position: relative;
}
ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: $ul-padding-right;
}
li {
  display: inline-block;
}
* a {
  color: $primary-color;
}
*.btn:focus {
  box-shadow: $btn-focus-box-shadow !important;
}
p {
  color: $grey;
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
}
code {
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
}
a {
  transition: 0.5s ease;
  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }
  &:focus {
    outline: none;
  }
}
button {
  &:focus {
    outline: none;
  }
}
:focus {
  outline-color: $all-focus-outline-color;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
svg {
  vertical-align: baseline;
}
input {
  &:focus {
    outline-color: $transparent-color;
  }
}
.txt-dark {
  color: $theme-font-color !important;
}
.txt-success {
  color: $success-color !important;
}
.txt-danger {
  color: $danger-color !important;
}
/**=====================
      General CSS Ends
==========================**/

/**=====================
    Header CSS Start
==========================**/

$header-size: 80px;

%common {
  color: $theme-body-font-color;
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
/*======= Page Header css Start ======= */
.page-wrapper {
  .page-main-header {
    background-color: $main-header-bg-color;
    height: $header-size;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    box-shadow: $main-header-shadow;
    width: calc(100% - #{$sidebar-width});
    display: flex;
    align-items: center;
    margin-left: $sidebar-width;
    transition: $sidebar-transition;
    &.open {
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper {
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      background-color: $light-color;
    }
    .page-header {
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;
      .row {
        align-items: center;
        .page-header-left {
          display: flex;
          align-items: center;
        }
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;
          font-family: $font-nunito;
          small {
            display: block;
            font-size: 12px;
            margin-top: 7px;
            letter-spacing: 1px;
            text-transform: capitalize;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        .breadcrumb-item {
          font-family: $font-nunito;
          & + .breadcrumb-item {
            &:before {
              content: $breadcrumb-content;
            }
          }
          a {
            svg {
              width: $breadcrumb-size;
              height: $breadcrumb-size;
              vertical-align: $breadcrumb-svg-icon-align;
            }
          }
        }
      }
    }
  }
}
/*======= Page Header css ends  ======= */

/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw;

  .main-header-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: $main-header-right-padding;
    margin: 0;
    svg {
      color: $main-header-right-toggle-color;
      line,
      polyline {
        color: $main-header-right-toggle-color;
      }
    }
    .nav-left {
      i {
        margin-right: 20px;
      }
      input:focus {
        outline: 0 !important;
      }
    }
    .nav-right {
      text-align: right;
      padding-left: $main-header-right-nav-right;
      ul {
        li {
          .media {
            .dotted-animation {
              position: relative;
              right: -3px;
              top: -7px;
              .animate-circle {
                position: absolute;
                top: -20px;
                right: -4px;
                height: 25px;
                width: 25px;
                z-index: 10;
                border: 5px solid $primary-color;
                border-radius: 70px;
                animation: heartbit 1s ease-out;
                animation-iteration-count: infinite;
              }
              .main-circle {
                width: 6px;
                height: 6px;
                border-radius: 30px;
                background-color: $primary-color;
                position: absolute;
                right: 6px;
                top: -10px;
              }
            }
          }
          svg {
            margin-top: $main-header-right-nav-icon-margin-top;
            width: $main-header-right-nav-icon-size;
            height: $main-header-right-nav-icon-size;
            path {
              color: $main-header-right-nav-icon-color;
            }
          }
          .dot {
            width: 3px;
            height: 3px;
            border-radius: 30px;
            background-color: $main-header-right-nav-icon-color;
            position: absolute;
            right: 17px;
            bottom: 6px;
            animation: blink 1.5s infinite;
          }
          .dot-chat {
            right: 4px;
            bottom: -11px;
          }
        }
      }
      .notification {
        position: absolute;
        top: 21px;
        right: -1px;
        font-size: 9px;
        animation: blink 1.5s infinite;
        @keyframes blink {
          0% {
            opacity: 1;
          }

          70% {
            opacity: 1;
          }

          80% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }
      }
      .icon-user {
        font-size: 16px;
      }
      @keyframes heartbit {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        25% {
          transform: scale(0.1);
          opacity: 0.1;
        }
        50% {
          transform: scale(0.5);
          opacity: 0.3;
        }
        75% {
          transform: scale(0.8);
          opacity: 0.5;
        }
        100% {
          transform: scale(1);
          opacity: 0;
        }
      }
      > ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > li {
          position: relative;
          border-left: 1px solid $light-semi-gray;
          padding: 0 20px;
          &:first-child {
            width: 25%;
            border-left: none;
            .search-form {
              .form-group {
                width: 100%;
                position: relative;
                &:focus {
                  outline-color: transparent;
                }
                input {
                  &:focus {
                    outline-color: transparent;
                  }
                }
                &:before {
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 25px;
                  background: $light-gray;
                  left: 51px;
                  top: 9px;
                }
                &:after {
                  position: absolute;
                  content: "\f002";
                  font-family: $font-awesome;
                  top: 11px;
                  left: 22px;
                  color: #8e8e8e;
                }
              }
            }
          }
          &:last-child {
            border-left: none;
            padding-right: 0;
          }
          &:nth-child(5) {
            border-left: none;
            border-right: 1px solid $light-semi-gray;
            padding-left: 0;
          }
          h6 {
            margin-top: 4px;
            margin-bottom: 4px;
            color: $primary-color;
            ul {
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,
              &:after {
                left: inherit;
                right: 10px;
              }
              li {
                display: block;
                a {
                  font-size: 14px;
                  color: $dark-color;
                  i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon {
          font-size: 16px;
        }
      }
      .notification-dropdown {
        top: 57px;
      }
      .language-dropdown {
        width: 160px;
        text-align: left;
        top: 57px;
        li {
          padding-top: 10px;
          a {
            color: $dark-color;
            i {
              margin-right: 10px;
            }
          }
          &:first-child {
            padding-top: 0;
          }
        }
      }
      .profile-dropdown {
        right: -10px;
        left: inherit;
        width: 150px;
        top: 63px;
        &:before,
        &:after {
          left: inherit;
          right: 10px;
        }
        li {
          display: block;
          text-align: left;
          padding-top: 10px;
          &:nth-child(3) {
            padding-bottom: 10px;
          }
          &:nth-child(4) {
            border-top: 1px solid $light-semi-gray;
          }
          &:first-child {
            padding-top: 0;
          }
          &:hover {
            a {
              color: $primary-color;
              transition: $sidebar-transition;
              svg {
                color: $primary-color !important;
                path,
                line,
                polyline {
                  color: $primary-color !important;
                }
              }
            }
          }
          a {
            color: $theme-body-font-color;
            transition: $sidebar-transition;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
              color: $theme-body-font-color;
              polyline {
                color: $theme-body-font-color;
              }
            }
          }
        }
      }
    }
    li {
      display: inline-block;
      position: relative;
    }
  }
}
.nav-menus {
  .notification-badge {
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 4px 7px;
  }
  .onhover-dropdown {
    cursor: pointer;
    position: relative;
    &:before {
      display: none;
    }
    &:hover {
      .onhover-show-div {
        @extend %for-animated-hover-box;
      }
    }
  }
  .search-form {
    input {
      border: 1px solid #eff0f1;
      padding: 10px 10px 10px 70px;
      border-radius: 50px;
      background-color: $light-color;
    }
  }
}

ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -18px;
  left: initial;
  &:before,
  &:after {
    left: inherit !important;
    right: 35px !important;
  }
  li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid $light-color;
    text-align: left;
    h6 {
      small {
        padding-top: 5px;
        color: $dark-gray;
        font-size: 12px;
      }
    }
    span {
      svg {
        margin-top: 0 !important;
        margin-right: 10px;
        vertical-align: text-top;
      }
      .shopping-color {
        path,
        line {
          color: $primary-color;
        }
      }
      .download-color {
        path,
        line {
          color: $success-color;
        }
      }
      .alert-color {
        path,
        line {
          color: $danger-color;
        }
      }
    }
    p {
      margin-left: 30px;
    }
    + li {
      &:hover {
        background-color: $light-color;
      }
    }
  }
}
.onhover-show-div {
  top: $header-size;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
/**=====================
    Sidebar CSS Start
==========================**/
.page-wrapper {
  .page-body-wrapper {
    .sidebar {
      height: calc(100vh - #{$header-size});
      overflow: auto;
      box-shadow: $sidebar-shadow;
    }
    .page-sidebar {
      width: $sidebar-width;
      position: $sidebar-position;
      background: $sidebar-background-color;
      top: 0;
      height: calc(100vh);
      z-index: $sidebar-z-index;
      transition: $sidebar-transition;
      .main-header-left {
        display: inline-flex;
        width: 100%;
        height: $header-size;
        padding: $main-header-padding;
        align-items: center;
        background-color: $main-header-left-bg-color;
        z-index: $main-header-z-index;
        box-shadow: -3px 1px 3px 1px rgba(68, 102, 242, 0.1);
        .logo-wrapper {
          padding-left: 10px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .sidebar-user {
        padding: $sidebar-user-section-padding;
        box-shadow: $sidebar-user-section-shadow;
        position: relative;
        h6 {
          color: $sidebar-profile-name-txt-color;
          text-transform: $sidebar-profile-name-txt-transfer;
          font-weight: $sidebar-profile-name-txt-weight;
          letter-spacing: $sidebar-profile-name-letter-specing;
          margin-bottom: $sidebar-profile-name-marging;
        }
        p {
          text-transform: $sidebar-profile-name-txt-transfer;
          font-weight: $sidebar-profile-name-txt-weight;
          font-size: $sidebar-profile-sub-title-font-size;
          margin-bottom: $sidebar-profile-sub-title-margin;
        }
        img {
          box-shadow: $sidebar-profile-img-shadow;
        }
      }
      .sidebar-menu {
        list-style: $sidebar-menu-list-style;
        margin: $sidebar-menu-list-margin;
        padding: $sidebar-menu-padding;
        .sidebar-header {
          font-size: $sidebar-font-size;
          letter-spacing: $sidebar-letter-specing;
          padding-bottom: $sidebar-padding-bottom;
          padding-top: $sidebar-padding-top;
          text-transform: $sidebar-txt-transform;
          font-weight: $sidebar-font-weight;
          color: $sidebar-font-color;
          svg {
            width: $sidebar-icon-size;
            height: $sidebar-icon-size;
            margin-right: $sidebar-icon-margin;
            stroke-width: $sidebar-icon-stroke-width;
            vertical-align: text-bottom;
          }
        }
        .sidebar-submenu {
          &.menu-open {
            li {
              &.active {
                .fa-angle-down {
                  &:before {
                    content: "\f104";
                    transition: $sidebar-transition;
                  }
                }
              }
            }
          }
        }
        > li {
          > a {
            display: block;
            transition: $sidebar-transition;
            &.active {
              color: $sidebar-hover-txt-color;
              transition: $sidebar-transition;
            }
            &:hover {
              color: $sidebar-hover-txt-color;
              padding-left: $sidebar-heading-hover-padding;
              transition: $sidebar-transition;
            }
            i {
              margin-right: 7px;
              text-align: right;
              margin-top: 3px;
              font-size: $sidebar-arrow-size;
              &:before {
                content: $sidebar-close-icon;
              }
              & ~ i {
                margin-right: 0;
              }
            }
          }
          .label {
            margin-top: 3px;
            margin-right: 5px;
          }
          .badge {
            margin-left: 50px;
            text-transform: capitalize;
          }
          &:hover > a {
            @extend %common;
          }
          &.active > a {
            @extend %common;
          }
        }
        li {
          &.sidebar-header {
            margin-bottom: $sidebar-sub-header-margin;
            padding: $sidebar-sub-header-padding;
            color: $sidebar-font-color;
            font-weight: $sidebar-font-weight;
            transition: $sidebar-transition;
          }
          > a {
            > .fa-angle-down {
              width: auto;
              height: auto;
              padding: 0;
              margin-right: 10px;
              margin-top: 10px;
              transform: rotate(-90deg);
              transition: $sidebar-transition;
            }
          }
          &.active {
            > {
              a {
                > .fa-angle-right {
                  &:before {
                    content: $sidebar-open-icon;
                    font-family: $sidebar-icon-font-family;
                    transition: $sidebar-transition;
                  }
                }
              }
            }
            > .sidebar-submenu {
              display: block;
              transform: rotateX(0deg);
              transform-origin: 10% 10%;
              transition: transform $sidebar-transition, opacity $sidebar-transition;
              transform-style: preserve-3d;
              margin-left: 25px;
              a {
                i.pull-right {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        a {
          text-decoration: none;
        }
        .sidebar-submenu {
          display: none;
          transform-style: preserve-3d;
          transform: rotateX(-90deg);
          list-style: none;
          .sidebar-submenu {
            padding-top: 0 !important;
          }
          > li {
            > a {
              padding-bottom: 7px;
              padding-top: 7px;
              font-size: 14px;
              color: $dark-gray;
              transition: $sidebar-transition;
              text-transform: $sidebar-text-transform;
              position: relative;
              padding-left: 10px;
              line-height: 2.5;
              letter-spacing: 0.7px;
              > {
                .fa-circle {
                  width: 12px;
                  font-size: 4px;
                  position: absolute;
                  left: 0;
                  top: 13px;
                }
                i {
                  width: auto;
                }
                .fa-angle-down {
                  width: auto;
                }
              }
              &:hover {
                color: $primary-color;
                transition: $sidebar-transition;
              }
              &.active {
                color: $primary-color;
              }
            }
            &.active > a {
              color: $theme-body-font-color;
              &.active {
                color: $primary-color;
              }
            }
          }
        }
      }
      ~ .page-body {
        margin-left: $sidebar-width;
        transition: $sidebar-transition;
      }

      &.open {
        display: block;
        margin-left: calc(-#{$sidebar-width + 5px});
        ~ .page-body {
          margin-left: 0;
          transition: $sidebar-transition;
        }
        ~ footer {
          margin-left: 0;
          padding-right: 15px;
        }
        ~ .footer-fix {
          width: calc(100% - 0px);
        }
      }
    }
    .sidebar-close {
      .page-sidebar {
        transition: $sidebar-transition;
        transform: translate(-$sidebar-width);
      }
      .page-body {
        transition: $sidebar-transition;
        margin-left: 0 !important;
      }
    }
  }
}
.right-sidebar {
  top: 81px;
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 9;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  &.show {
    right: 0;
    transition: $sidebar-transition;
  }
  .modal-header {
    .modal-title {
      padding-top: 2px;
    }
  }
  .friend-list-search {
    position: relative;
    background-color: $light-color;
    padding: 20px;
    input {
      color: $dark-gray;
      width: 100%;
      background-color: $white;
      border: 1px solid $light-color;
      padding: 10px 15px;
      border-radius: 25px;
      letter-spacing: 1px;
    }
    .fa {
      position: absolute;
      right: 35px;
      top: 34px;
      font-size: 14px;
      color: $light-gray;
    }
  }
  .chat-box {
    .people-list {
      ul {
        padding-top: 20px;
        li {
          position: relative;
        }
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
}

/**=====================
    Sidebar CSS Ends
==========================**/

/**=====================
      Generic CSS Start
==========================**/

/*====== Padding css starts ======*/
$i: 0;
@while $i<=50 {
  .p-#{$i} {
    padding: #{$i}px;
  }
  $i: $i + 5;
}
/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;
@while $i<=50 {
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }
  $i: $i + 5;
}
/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;
@while $i<=50 {
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/
$i: 0;
@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;
@while $i<=50 {
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }
  $i: $i + 5;
}
/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/
$i: 0;
@while $i<=50 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;
@while $i<=50 {
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;
@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;
@while $i<=50 {
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  $i: $i + 5;
}
/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;
@while $i<=50 {
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }
  $i: $i + 5;
}
/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i: 0;
@while $i<=10 {
  .b-r-#{$i} {
    border-radius: #{$i}px !important;
  }
  $i: $i + 1;
}
/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/
$i: 12;
@while $i<=100 {
  .f-#{$i} {
    font-size: #{$i}px;
  }
  $i: $i + 2;
}
/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/
$i: 100, 300, 400, 600, 700, 900;
@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}
/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/
$i: normal, italic, oblique, initial, inherit;
@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}
/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/

$i: overline, line-through, underline, dashed, blink, dotted, initial, none, solid, wavy, inherit, double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/
$i: baseline, sub, super, top, text-top, middle, bottom, text-bottom, initial, inherit;
@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}
/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

$i: static, absolute, fixed, relative, initial, inherit;
@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}
/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i: left, right, none;
@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}
/*====== Float css ends ======*/

/*====== Overflow css starts ======*/
$i: hidden, visible, auto;
@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}
/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/
$i: 10;
@while $i<=100 {
  .img-#{$i} {
    width: #{$i}px !important;
  }
  $i: $i + 10;
}
/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name: primary, secondary, success, danger, info, light, dark, warning;
$text-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
@each $var in $text-name {
  $i: index($text-name, $var);
  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}
/*======= Text css ends ===========*/

/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */

$badge-name: primary, secondary, success, danger, info, light, dark, warning;
$badge-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
@each $var in $badge-name {
  $i: index($badge-name, $var);
  .badge-#{$var} {
    background-color: nth($badge-color, $i);
  }
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */

$background-name: primary, secondary, success, danger, info, light, dark, warning;
$background-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color,
  $dark-color, $warning-color;
@each $var in $background-name {
  $i: index($background-name, $var);
  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name: primary, secondary, success, danger, info, light, dark, warning, google-plus, twitter, linkedin, fb;
$label-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
/*======= Font-color css end  ======= */

/*======= Button-color css starts  ======= */
// btn--color//
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .btn-#{$btn-name} {
    background-color: $btn-color !important;
    border-color: $btn-color !important;
    &.disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:disabled {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }
}
/*======= Button-color css ends  ======= */

//active button css //

@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

//* active button css end *//

@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color),
  (danger, $danger-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: white;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary, secondary, success, danger, info, light, dark, warning;
$border-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
@each $var in $border-name {
  $i: index($border-name, $var);
  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

$table-name: primary, secondary, success, danger, info, light, dark, warning;
$table-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
@each $var in $table-name {
  $i: index($table-name, $var);
  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);
    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}

/*======= Table styling css ends  ======= */

/*======= All-Borders-color css starts  ======= */

$b-name: primary, secondary, success, danger, info, light, dark, warning;
$b-color: $primary-color, $secondary-color, $success-color, $danger-color, $info-color, $light-color, $dark-color,
  $warning-color;
@each $var in $b-name {
  $i: index($b-name, $var);
  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }
  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }
  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }
  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }
  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}

/*======= All-Borders-color css ends  ======= */

/*====== Border width css starts ======*/
$i: 1;
@while $i<=10 {
  .border-#{$i} {
    border-width: #{$i}px !important;
  }
  $i: $i + 1;
}
/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}
.shadow-0 {
  box-shadow: none;
}

/**====== custom scrollbar css start ======**/
.digits {
  font-family: $font-work-sans, $font-serif;
}
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color, 0.17);
  }
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.line {
  pre {
    font-size: 100%;
  }
}
/**====== Animation css end ======**/

/**=====================
      Generic CSS Ends
==========================**/

/**=====================
     Chat CSS Start
==========================**/
.chat-box {
  .toogle-bar {
    display: none;
  }
  .people-list {
    .search {
      position: relative;
      .form-control {
        background-color: $light-body-bg-color;
        border: 1px solid $light-color;
        &::placeholder {
          color: $semi-dark;
        }
      }
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 14px;
        color: $light-gray;
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  .status {
    color: $semi-dark;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status {
      font-weight: 600;
      color: $theme-body-font-color;
    }
    p {
      font-size: 14px;
    }
  }
}
.status-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  border: 2px solid $white;
}
.away {
  background-color: $warning-color;
}
.online {
  background-color: $success-color;
}
.offline {
  background-color: $danger-color;
}
/**=====================
      Chat CSS Ends
==========================**/

.market-chart {
  height: 303px;
}
.needs-validation {
  .custom-select {
    background: white;
  }
  .form-control {
    border-radius: 0.25rem;
    padding: 0.48rem 0.75rem;
  }
}

.license-key[disabled] {
  cursor: not-allowed;
}

.checkbox {
  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .form-check-input {
    margin-top: 13px;
    margin-bottom: 10px;
  }
}
.form-row {
  .form-control {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .custom-select {
    font-size: 14px;
    letter-spacing: 1px;
  }
}

/*=====================
    Responsive CSS start
==========================*/
@media only screen and (max-width: 1660px) {
  .products-table {
    table {
      tr {
        td {
          &:first-child {
            min-width: 268px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .product-physical {
    .ReactTable {
      .rt-table {
        .rt-tr {
          .rt-th {
            &:nth-child(2) {
              width: 200px !important;
            }
          }
        }
        .rt-tr-group {
           .rt-td {
             &:nth-child(2) {
               width: 200px !important;
             }
           }
        }
      }
    }
  }
  .flot-chart-container {
    height: 350px;
  }
  .product-adding {
    .col-xl-5 {
      flex: 0 0 36%;
      max-width: 36%;
    }
    .col-xl-7 {
      flex: 0 0 64%;
      max-width: 64%;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .report-employee {
    h2 {
      font-size: 32px;
    }
  }
  .add-product-form {
    .qty-box {
      width: 24%;
    }
  }
  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            &:first-child {
              width: 32%;
            }
          }
        }
      }
    }
  }
  .product-adding {
    .add-product-form {
      margin-top: 30px;
    }
  }
  .user-list {
    table {
      tr {
        td,
        th {
          &:nth-child(5) {
            width: 195px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  // product detail
  .product-page-details {
    margin-top: 30px;
  }
  .user-list {
    table {
      tr {
        th,
        td {
          width: 200px !important;
        }
      }
    }
  }
}
@media screen and (max-device-width: 1366px) and (min-device-width: 1200px) {
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .product-adding {
    .col-xl-5,
    .col-xl-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .add-product-form {
      margin-top: 30px;
    }
  }
  .user-list {
    table {
      tr {
        td,
        th {
          &:first-child {
            width: 130px !important;
          }
        }
      }
    }
  }
  // profile
  .profile-table {
    table {
      tr {
        th,
        td {
          width: 200px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .authentication-box {
    padding: 30px;
    .container {
      .bg-primary{
        padding: 40px;
      }
    }
  }
  .btn-popup {
    margin-bottom: 20px;
  }
  .order-graph {
    .order-graph-bottom {
      margin-top: 20px;
    }
  }
  .card {
    .card-body,
    .card-header {
      padding: 20px;
    }
  }
  .page-wrapper {
    .page-main-header {
      &.open {
        z-index: 11;
      }
    }
  }
  .right-sidebar {
    top: 127px;
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          h3 {
            font-size: 22px;
          }
          .pull-right {
            float: none;
            margin-top: 5px;
          }
        }
      }
      .page-sidebar {
        top: 60px !important;
        height: calc(100vh - 60px) !important;
        ~ {
          .page-body {
            margin-left: 0;
            margin-top: 60px;
            .page-header {
              .row {
                .page-header-left {
                  display: inherit;
                }
              }
            }
          }
        }
      }
      .sidebar {
        height: calc(100vh - 60px);
      }
      .page-sidebar.open {
        ~ {
          .page-body {
            margin-top: 60px;
          }
        }
      }
      footer {
        margin-left: 0;
      }
    }
    .page-main-header {
      height: 60px;
      margin-left: 0;
      width: 100%;
      z-index: 11;
      .main-header-left {
        .logo-wrapper {
          img {
            height: 23px;
            margin-top: 0;
            margin-right: 15px;
          }
        }
      }
      .main-header-right {
        .nav-right {
          position: unset;
          padding: 0;
          > ul {
            top: 60px;
            position: absolute;
            z-index: -1;
            background-color: #fff;
            -webkit-transition: all linear 0.3s;
            transition: all linear 0.3s;
            -webkit-box-shadow: 0 2px 2px 2px #efefef;
            box-shadow: 0 2px 2px 2px #efefef;
            width: 100%;
            left: 0;
            padding: 0 40px;
            -webkit-transform: translateY(-35px) scaleY(0);
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            > li {
              margin: 10px 0;
            }
          }
          > .mobile-toggle {
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            svg {
              circle {
                color: $theme-deafult;
              }
            }
          }
          > ul.open {
            z-index: 1;
            opacity: 1;
            -webkit-transform: translateY(0px) scaleY(1);
            transform: translateY(0px) scaleY(1);
            visibility: visible;
          }
        }
      }
    }
  }
  .page-header {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    .row {
      .page-header-left {
        .breadcrumb {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }
  // product detail
  .product-page-details {
    text-align: left !important;
  }
  // add product page
  .add-product {
    .bigImg{
      img {
        width: 460px;
      }
    }
    
  }
  .product-adding {
    .add-product-form {
      margin-top: 20px;
    }
  }
  // tab css
  .tab-coupon {
    margin-bottom: 20px;
  }
  // profile
  .deactivate-account {
    margin-top: 20px;
  }
  // reports
  .sell-graph {
    canvas {
      height: 312px !important;
    }
  }
  // forms 
  .needs-validation {
    h4 {
      font-size: 16px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .checkbox-space {
    padding: 0 15px;
  }
  .react-datepicker__input-container {
    margin-left: 15px;
  }
  .authentication-box {
    .form-footer,.form-button{
      text-align: center;
      >span{
        padding: 0;
        left: 0;
        right: 0;
      }
    }
  }
  .ReactTable {
    .rt-thead {
      .rt-resizable-header-content {
        .btn {
          padding: 6px 12px;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            &:first-child {
              width: 40%;
            }
          }
        }
      }
    }
  }
  .products-table {
    table {
      tr {
        td,
        th {
          &:first-child {
            min-width: 280px;
          }
          &:nth-child(3) {
            min-width: 110px;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          .col {
            flex-basis: unset;
            .pull-right {
              float: none;
              margin-top: 20px;
            }
          }
        }
      }
      footer {
        .row {
          div {
            text-align: center;
            .pull-right {
              float: none;
            }
          }
        }
      }
    }
  }
  .footer-fix {
    .pull-right {
      float: none !important;
      text-align: center !important;
    }
  }
  // add product page
  .add-product-form {
    .form-group {
      .form-control,
      select {
        width: 100% !important;
        margin: 0 !important;
      }
    }
    .input-group {
      .touchspin {
        width: 30% !important;
      }
    }
    .radio-animated {
      label {
        margin-bottom: 0;
      }
    }
    .editor-space {
      padding: 0 15px;
    }
    textarea {
      margin: 0 15px;
    }
    .qty-box {
      width: 29%;
    }
  }
  .add-product {
    .bigImg{
      img {
        width: 400px;
      }
    }
  }
  .digital-add {
    textarea {
      margin: 0 !important;
    }
    .form-group {
      select,
      .form-control {
        width: 100% !important;
      }
      label {
        margin-bottom: 0 !important;
      }
    }
  }
  // coupon page
  .needs-validation {
    .form-group {
      &.row {
        margin-left: 0;
        margin-right: 0;
      }
      .form-control,
      select {
        width: 95%;
        margin: 0 auto;
      }
      .checkbox {
        padding-left: 15px;
      }
      label {
        margin-bottom: 4px;
      }
    }
    .radio-animated {
      label {
        margin-bottom: 0;
      }
    }
    .editor-space {
      padding: 0 15px;
    }
    textarea {
      margin: 0 15px;
    }
  }
}
@media only screen and (max-width: 577px) {
  footer {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .authentication-box .btn-primary {
    margin-top: 10px;
  }
  .sales-chart {
    height: 200px;
  }
  .report-last-sm {
    canvas {
      height: 200px !important;
    }
  }
  .dropzone {
    .dzu-input {
      width: 90%;
    }
  }
  .product-right {
    .timer {
      span {
        width: 55px;
      }
    }
  }
  .market-chart {
    height: 205px;
  }
  .ct-4 {
    &.flot-chart-container {
      height: 240px;
    }
  }
  .sell-graph {
    canvas {
      height: 250px !important;
    }
  }
  .btn-popup {
    margin-bottom: 15px;
  }
  .static-top-widget {
    div {
      &.align-self-center {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .latest-order-table {
    table {
      tr {
        td {
          min-width: 100px;
          + td {
            min-width: 150px;
          }
          &:nth-child(3) {
            min-width: 200px;
          }
        }
      }
    }
  }
  .card {
    margin-bottom: 15px;
    .card-header {
      padding: 15px;
      h5 {
        font-size: 17px;
      }
    }
    .card-body {
      padding: 15px;
    }
  }
  .sm-order-space,
  .xl-space {
    margin-top: 15px;
  }
  .order-graph {
    .order-graph-bottom {
      margin-top: 15px;
      h6 {
        margin-right: 0;
      }
      .media {
        margin-bottom: 15px;
      }
    }
  }
  .form-inline {
    .form-control-plaintext {
      display: inline-block;
    }
  }
  .d-sm-none {
    &:after {
      display: none;
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 284px;
        right: -112px;
        &:before,
        &:after {
          right: 135px !important;
        }
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        padding: 0 15px;
        .nav-right {
          > ul {
            padding: 0 0;
            justify-content: flex-start;
            > li {
              &:first-child {
                .search-form {
                  .form-group {
                    &:before {
                      display: none;
                    }
                    &:after {
                      display: none;
                    }
                  }
                }
                width: auto;
                margin: 0;
              }
            }
            .search-form {
              .form-group {
                margin-bottom: 0;
              }
              .form-control-plaintext.open {
                -webkit-transform: translateY(0px) scaleY(1);
                transform: translateY(0px) scaleY(1);
                opacity: 1;
                visibility: visible;
                -webkit-transition: all linear 0.3s;
                transition: all linear 0.3s;
              }
              .form-control-plaintext {
                top: 60px;
                position: absolute;
                -webkit-transition: all linear 0.3s;
                transition: all linear 0.3s;
                left: 0;
                background-color: #fff;
                -webkit-transform: translateY(-35px) scaleY(0);
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                width: 180px;
                padding: 10px 10px 10px 15px;
              }
              .mobile-search {
                svg {
                  color: $theme-deafult;
                }
              }
            }
          }
        }
      }
    }
    .search-form {
      .form-group {
        margin-right: 0;
      }
    }
    .page-body-wrapper {
      .page-sidebar.open {
        ~ {
          .page-body {
            .activity {
              .media {
                .gradient-round.gradient-line-1 {
                  &:after {
                    bottom: -41px;
                  }
                }
                .gradient-round.small-line {
                  &:after {
                    bottom: -19px;
                    height: 12px;
                  }
                }
                .gradient-round.medium-line {
                  &:after {
                    bottom: -34px;
                  }
                }
              }
            }
          }
        }
      }
      .page-body {
        padding: 0;
      }
      .page-header {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }
  // profile
  .profile-details {
    img {
      margin-bottom: 15px;
    }
  }
  .project-status {
    margin-top: 15px;
    .media {
      margin-top: 15px;
    }
  }
  .profile-card {
    ul {
      display: inherit;
      margin-bottom: 15px;
      li {
        display: block;
       text-align: center;
      }
    }
  }
  .profile-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
  .account-setting {
    h5 {
      margin-bottom: 15px;
    }
  }
  .deactivate-account {
    margin-top: 15px;
    .btn {
      margin-top: 15px;
    }
  }
  // create user
  .sm-label-radio {
    margin-bottom: 4px !important;
  }
  .permission-block {
    .attribute-blocks {
      .form-group {
        padding-bottom: 15px;
      }
    }
  }
  .needs-validation {
    textarea {
      margin: 0 10px;
    }
    h4 {
      margin-bottom: 15px;
    }
    .permission-block {
      .attribute-blocks {
        .row {
          padding-left: 15px;
        }
        + .attribute-blocks {
          h5 {
            margin-top: 20px;
          }
        }
      }
    }
    .radio_animated {
      margin: 0 6px 0 0;
    }
    .radio-animated {
      label {
        margin-right: 15px;
      }
    }
  }
  // tab css
  .tab-coupon {
    display: grid;
    text-align: center;
  }
  // add product css
  .add-product-form {
    .form-group {
      .form-control {
        width: 93%;
        margin: 0 auto;
      }
      .col-xl-8 {
        padding: 0;
      }
      fieldset {
        padding-left: 0;
      }
      label {
        padding: 0;
      }
    }
    .qty-box {
      width: 162px;
      .input-group {
        .form-control {
          width: 80px;
        }
      }
    }
    .description-sm {
      padding: 0 !important;
    }
  }
  .add-product {
    ul {
      li {
        .box-input-file {
          width: 15px;
          height: 15px;
          img {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }
    }
  }
  .file-upload-product {
    margin-top: 11px;
    li {
      margin-bottom: 34px !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .authentication-box {
    padding: 30px 15px;
    .forgot-pass{
      float: none;
      padding-top: 10px;
    }
    .container {
      .form-group {
        margin-bottom: 1rem;
      }
      h3{
        font-size: 20px;
      }
      p{
        line-height: 1.6;
      }
      .bg-primary{
        padding: 30px 15px;
      }
      .svg-icon {
        padding: 17px;
        height: 90px;
        width: 90px;
        margin-bottom: 25px;
        svg{
          height: 50px;
        }
      }
    }
  }
  .nav-menus {
    .notification-badge {
      right: 0;
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        .nav-right {
          > ul {
            > li {
              padding: 0 13px;
              .dot {
                right: 13px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    padding-left: 0;
    padding-right: 0 !important;
    p {
      font-size: 12px;
    }
  }
  .add-product {
    .bigImg{
      img {
        width: 300px;
      }
    }
  }
  // product detail
  .product-page-main {
    .btn {
      padding: 5px 12px;
    }
  }
}
@media only screen and (max-height: 800px) {
  .right-sidebar {
    .chat-box {
      .friend-list {
        max-height: calc(100vh - 150px);
        overflow: scroll;
      }
    }
  }
}

/*=====================
  Responsive CSS End
==========================*/
.authentication-box .svg-icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding-left: 9px;
}