.modal-dialog.delete-modal-dialog {
    margin: 2.75rem auto !important;
    /* top: 30% !important; */
  }
  .delete-modal-dialog .modal-content {
    border: none;
    border-radius: 20px;
  }
  .delete-button {
    background-color:#ff4d53 !important;
    border-color:#ff4d53 !important;
    color:#fff !important;
    border-radius: 30px;
  }
  .delete-button:hover {
    background-color:#f0393f !important;
    border-color:#f0393f !important;
    color:#fff !important;
  }
  .cancel-button {
    background-color: #919191 !important;
    border-color: #919191 !important;
    border-radius: 30px;
  }
  .cancel-button:hover {
    background-color: #878787 !important;
    border-color: #878787 !important;
  }
  .delete-modal-header {
    border: none;
    padding: 0.5rem 1rem 0;
  }
  .delete-modal-header button span {
    font-size: 50px;
    color: #7d7d7d;
    position: absolute;
    font-weight: 100;
    top: 0px;
    right: 15px;
  }
  .delete-modal-dialog .modal-body {
    text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:5px;
      margin-top: 10px;
  }
  .delete-modal-dialog .modal-body img {
    width: 55px;
    margin-bottom: 10px;
  }
  .delete-modal-dialog .modal-footer {
    border: none;
    justify-content: center;
  }
  .delete-modal-dialog .modal-content {
      border: none;
      border-radius: 5px;
      padding: 10px 5px;
  }
  .delete-modal-dialog h3 {
      font-size: 19px;
      font-weight: 400;
      color: #000000;
      letter-spacing: 0.03em;
      margin: 20px 0px;
  }